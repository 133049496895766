<template>
  <div class="container">
    <PageTitle>Users' History Record</PageTitle>
    <hr />
    <h4>Search users</h4>
    <el-input
      placeholder="Search IP or user"
      prefix-icon="el-icon-search"
      v-model="search"
      @change="handleSearch"
    ></el-input>

    <el-table :data="pageViews">
      <el-table-column label="Timestamp" min-width="30">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="IP" min-width="30">
        <template slot-scope="scope">
          {{ scope.row.ip }}
        </template>
      </el-table-column>
      <el-table-column label="URL">
        <template slot-scope="scope">
          {{ scope.row.url }}
        </template>
      </el-table-column>
      <el-table-column label="User">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.email : "-" }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="getPageViews"
      :total="totalRecord"
    >
    </el-pagination>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import apiPageView from "@/apis/page-view.js";
import { instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: "Users' Page History - " + this.CompanyName
    };
  },
  components: {
    PageTitle
  },
  computed: {
    instant() {
      return instant;
    }
  },
  data() {
    return {
      search: "",
      pageViews: [],
      pageSize: 25,
      currentPage: 1,
      totalRecord: 1
    };
  },
  async created() {
    if (this.$route.query.email) {
      const selectedUser = await this.$store.dispatch("payment/fetchUsers", {
        keyword: this.$route.query.email
      });
      this.fetchPayments(selectedUser[0].id);
    }
  },
  methods: {
    getPageViews(page) {
      const currentPage = page || this.currentPage;
      apiPageView
        .get({
          keyword: this.search,
          page: currentPage,
          per_page: this.pageSize
        })
        .then(res => {
          this.pageViews = res.data;
          this.currentPage = res.current_page;
          this.totalRecord = res.total;
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleSearch() {
      this.currentPage = 1;
      this.getPageViews();
    }
  },
  mounted() {
    this.getPageViews();
  }
};
</script>

<style scoped lang="scss">
.container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.studentSelect ::v-deep .el-input__inner {
  cursor: text;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
